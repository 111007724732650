import { Component, OnInit } from "@angular/core";
import { HotelService } from "../../../shared/services/hotel.service";
import { HotelGuestModel } from "../hotel-guest-models/hotel.guest.model";
import { HotelGuestRoom } from "../hotel-guest-models/hotel.guests.room";
import { HotelGuestClientModel } from "../hotel-guest-models/hotel.client.model";
import { LoginService } from "@app/shared/services/login.service";
import { COMMUNES } from "@app/shared/services/misc/mock-communes";
import { LocalizationService } from "../../../shared/services/localization.service";
import { BaseService } from "../../../shared/services/base.service";
import { ShareVars } from "../../../shared/services/share.vars";
import { cloneDeep } from "lodash";
import { NavigationService } from "../../../shared/services/navigation.service";
import { callsigns } from "@app/shared/services/callsigns";
import { countries } from "@app/shared/services/countries";

@Component({
	selector: "app-hotel-guest",
	templateUrl: "./hotel-guest.component.html",
	styleUrls: ["./hotel-guest.component.scss"],
})
export class HotelGuestComponent implements OnInit {
	
	departements;
	towns;

	user = null;
	userType = null;

	hotelSearchParams;

	guestsInfosArray = new Array();

	roomsNBR: number = 0;

	clientInfos = new HotelGuestClientModel();

	clientCountryCodeDropdown = {
		mainButtonID: "hotel_guest_pax_client_countrycode_class_dropdown",
		mainButtonText: "+213 Algerie",
		dropdownAriaID: "hotel_guest_pax_client_countrycode_class_dropdown_aria",
		ariaButtonsID: [],
		//ariaButtonsText: ["+213 Algerie", "+212 Tunisie"],
		ariaButtonsText: callsigns.map((c) => c.code + " " + c.country),
		dropdownTypeString: true,
	};

	clientCitiesDropdown = {
		mainButtonID: "hotel_guest_pax_client_city_class_dropdown",
		dropdownAriaID: "hotel_guest_pax_client_city_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		dropdownTypeString: false,
	};

	clientTownsDropdown = {
		mainButtonID: "hotel_guest_pax_client_town_class_dropdown",
		dropdownAriaID: "hotel_guest_pax_client_town_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		dropdownTypeString: false,
	};

	clientCountriesDropdown = {
		mainButtonID: "hotel_guest_pax_client_country_class_dropdown",
		dropdownAriaID: "hotel_guest_pax_client_country_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		// ariaButtonsText: ["Algérie"],
		ariaButtonsText: countries,
		dropdownTypeString: false,
	};

	guestCivilityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "Mr.",
		ariaButtonsID: [],
		ariaButtonsText: ["Mr.", "Mrs.", "Ms."],
		dropdownTypeString: false,
	};
	isDuplicateFormEntries = false;

	constructor(
		private hotelService: HotelService,
		public baseService: BaseService,
		private loginService: LoginService,
		private localizationService: LocalizationService,
		private navigationService: NavigationService
	) {
		for (let i = 0; i < this.clientCountryCodeDropdown.ariaButtonsText.length; i++) {
			this.clientCountryCodeDropdown.ariaButtonsID[i] =
				"hotel_guest_pax_client_countrycode_" + i;
		}

		for (let i = 0; i < this.clientCountriesDropdown.ariaButtonsText.length; i++) {
			this.clientCountriesDropdown.ariaButtonsID[i] = "hotel_guest_pax_client_country_" + i;
		}
	}

	ngOnInit() {
		this.hotelSearchParams = cloneDeep(this.hotelService.getHotelBookingHotelSearchParams());
		// console.log("hotelSearch params", this.hotelSearchParams);

		if (this.hotelSearchParams) {
			if (Object.keys(this.hotelSearchParams).length > 0) {
				if (ShareVars.departements.length > 0) {
					this.departements = ShareVars.departements;
					this.initDepartementsDpd();
				} else {
					this.localizationService.getDepartements().subscribe((data) => {
						this.departements = data;
						ShareVars.departements = data;

						this.initDepartementsDpd();
					});
				}

				switch (this.baseService.b2bUserType) {
					case "B2C":
						this.userType = "B2C";
						if (ShareVars.currentUser != null) {
							this.initClientInfos(ShareVars.currentUser);
						} else {
							this.loginService.verifyToken().subscribe(
								(user) => this.initClientInfos(user),
								(error) => console.error(error)
							);
						}
						break;

					case "B2B":
						this.userType = "B2B";
						this.initClientInfos(new HotelGuestClientModel());
						break;
				}
			}

			this.initGuestsArrays(this.hotelSearchParams, this.guestsInfosArray);
		}
	}

	initDepartementsDpd() {
		for (let i = 0; i < this.departements.length; i++) {
			this.clientCitiesDropdown.ariaButtonsID[i] = "flight_passenger_pax_client_city_" + i;
			this.clientCitiesDropdown.ariaButtonsText[i] = this.departements[i]["name"];
		}

		this.towns = COMMUNES["Adrar"];
		this.clientInfos.clientTown = COMMUNES["Adrar"][0]["name"];

		this.clientTownsDropdown.mainButtonText = this.towns[0]["name"];
		this.clientCountriesDropdown.mainButtonText = "Algérie";

		for (let i = 0; i < this.towns.length; i++) {
			this.clientTownsDropdown.ariaButtonsID[i] = "flight_passenger_pax_client_town_" + i;
			this.clientTownsDropdown.ariaButtonsText[i] = this.towns[i]["name"];
		}
	}
	initClientInfos(user) {
		this.user = user;

		if (this.user.address) {
			this.clientInfos.clientAddress = this.user.address;
		}

		if (this.user.phone_number) {
			this.clientInfos.clientPhoneNumber = "0" + this.user.phone_number.slice(-9);
		}

		if (this.user.town) {
			this.clientInfos.clientTown = this.user.town;
			this.clientTownsDropdown.mainButtonText = this.clientInfos.clientTown;
		}

		if (this.user.email) {
			this.clientInfos.clientEmail = this.user.email;
		}

		if (this.user["country"]) {
			this.clientCountriesDropdown.mainButtonText = this.user["country"];
		}

		this.clientCitiesDropdown.mainButtonText = this.clientInfos.clientWilaya;
	}

	initGuestsArrays(hotelSearchParams, guestsInfosArray) {
		this.roomsNBR = hotelSearchParams["rooms"];

		for (let i = 0; i < this.roomsNBR; i++) {
			//room x
			const hotelGuestRoom = new HotelGuestRoom();

			//adults in room x

			hotelGuestRoom.guestRoom.adult_nbr = hotelSearchParams["adults_nbr_" + (i + 1)];
			hotelGuestRoom.guestRoom.room_remark = "";
			for (let k = 0; k < hotelGuestRoom.guestRoom.adult_nbr; k++) {
				//crate to each adult his infos
				const adultInfos = new HotelGuestModel();
				adultInfos.guestTypeName = "ADULT";
				adultInfos.guestType = "ADT";
				adultInfos.guestIndex = k;
				hotelGuestRoom.guestInfos.push(adultInfos);
			}

			//child in room x
			hotelGuestRoom.guestRoom.child_nbr = hotelSearchParams["child_nbr_" + (i + 1)];

			if (hotelGuestRoom.guestRoom.child_nbr > 0) {
				//create child age array in room x
				hotelGuestRoom.guestRoom.child_age = new Array();

				for (let j = 0; j < hotelGuestRoom.guestRoom.child_nbr; j++) {
					//create to each child his infos
					const childInfos = new HotelGuestModel();
					childInfos.guestTypeName = "CHILD";
					childInfos.guestType = "CHD";
					childInfos.guestIndex = j;
					hotelGuestRoom.guestInfos.push(childInfos);
					//add to each child his age
					hotelGuestRoom.guestRoom.child_age.push(
						hotelSearchParams["child_age_" + (i + 1) + "_" + (j + 1)]
					);
				}
			}

			//add room x to rooms array
			guestsInfosArray.push(hotelGuestRoom);
		}
	}

	setCivility(guest, civility) {
		guest["civility"] = civility;
		guest["gender"] = civility == "Mr" ? "M" : "F";
	}

	setCountryCode(code: string) {
		this.clientInfos.clientCountryCode = code.slice(0, code.indexOf(" "));
	}

	setWilaya(wilayaName) {
		this.towns = COMMUNES[wilayaName];
		this.clientInfos.clientTown = this.towns[0]["name"];
		this.clientInfos.clientWilaya = wilayaName;

		this.clientTownsDropdown.mainButtonText = this.towns[0]["name"];
		this.clientTownsDropdown.ariaButtonsText = [];
		for (let i = 0; i < this.towns.length; i++) {
			this.clientTownsDropdown.ariaButtonsText[i] = this.towns[i]["name"];
		}
	}

	setTown(townName) {
		this.clientInfos.clientTown = townName;
	}

	setCountry(countryName) {
		this.clientInfos.clientCountry = countryName;
	}

	getHotelBookingConfirmationRequest() {
		let confirmationRequest = {};
		let price = 0;
		let ratesArray = this.hotelService.getHotelBookingHotelRates();

		confirmationRequest["checkInDate"] = this.hotelSearchParams["checkIn"];
		confirmationRequest["checkOutDate"] = this.hotelSearchParams["checkOut"];

		confirmationRequest["hotelName"] = this.hotelService.getHotelBookingHotelItem()["name"];
		confirmationRequest["hotelCode"] = this.hotelService.getHotelBookingHotelCode();
		confirmationRequest["Comment"] = this.hotelService.getHotelBookingHotelSelectedComment();
		confirmationRequest["provider"] = this.hotelService.getHotelBookingHotelSelectedProvider();
		confirmationRequest['provider_id'] = this.hotelService.getHotelBookingHotelItem()["provider_id"] ;
		confirmationRequest["trace"] = true;

		confirmationRequest["customer_address"] = this.clientInfos.clientAddress;
		confirmationRequest["customer_city"] = this.clientInfos.clientTown;
		confirmationRequest["customer_country"] = this.clientInfos.clientCountry;
		confirmationRequest["customer_email"] = this.clientInfos.clientEmail;
		confirmationRequest["customer_phoneNumber"] = this.clientInfos.clientPhoneNumber;
		confirmationRequest["remark"] = this.clientInfos.remark;
		confirmationRequest["booking_remark"] = this.clientInfos.client_remark;
		confirmationRequest["customer_phone_countrycode"] = this.clientInfos.clientCountryCode;
		confirmationRequest["room"] = this.guestsInfosArray.length;

		for (let rateIndex = 1; rateIndex <= ratesArray.length; rateIndex++) {
			this.hotelService.supplementsArray
				.filter((el) => el.rateIndex === rateIndex)
				.forEach(function (supp, index) {
					confirmationRequest["supp_" + rateIndex + "_" + (index + 1)] =
						supp["supplement"]["SuppID"] +
						"@" +
						supp["supplement"]["SuppChargeType"] +
						"@" +
						supp["supplement"]["Price"];
				});
			confirmationRequest["supp_" + rateIndex] = this.hotelService.supplementsArray.filter(
				(el) => el.rateIndex === rateIndex
			).length;
			confirmationRequest["rateKey_" + rateIndex] = ratesArray[rateIndex - 1]["rateKey"];
			confirmationRequest["boarding_name_" + rateIndex] =
				ratesArray[rateIndex - 1]["boardName"];
			confirmationRequest["room_name_" + rateIndex] = ratesArray[rateIndex - 1]["roomName"];
			price += ratesArray[rateIndex - 1].price;
		}
		confirmationRequest["total_price"] = price;

		for (let i = 0; i < this.guestsInfosArray.length; i++) {
			confirmationRequest["adults_" + (i + 1)] = this.guestsInfosArray[i].guestRoom.adult_nbr;
			confirmationRequest["children_" + (i + 1)] =
				this.guestsInfosArray[i].guestRoom.child_nbr;
			confirmationRequest["remark_room_" + (i + 1)] =
				this.guestsInfosArray[i].guestRoom.room_remark;

			let childIndex: number = 0;

			for (let j = 0; j < this.guestsInfosArray[i].guestInfos.length; j++) {
				confirmationRequest["firstname_" + (i + 1) + "_" + (j + 1)] =
					this.guestsInfosArray[i].guestInfos[j]["firstName"];
				confirmationRequest["lastname_" + (i + 1) + "_" + (j + 1)] =
					this.guestsInfosArray[i].guestInfos[j]["lastName"];
				confirmationRequest["gender_" + (i + 1) + "_" + (j + 1)] =
					this.guestsInfosArray[i].guestInfos[j]["gender"];
				confirmationRequest["type_" + (i + 1) + "_" + (j + 1)] =
					this.guestsInfosArray[i].guestInfos[j]["guestType"];

				if (this.guestsInfosArray[i].guestInfos[j]["guestType"] == "CHD") {
					confirmationRequest["age_" + (i + 1) + "_" + (j + 1)] =
						this.guestsInfosArray[i].guestRoom.child_age[childIndex];
					childIndex++;
				}
			}
		}

		confirmationRequest["point_of_sale_id"] = null;

		let sortedRequest = {};

		Object.keys(confirmationRequest)
			.sort()
			.forEach(function (key) {
				sortedRequest[key] = confirmationRequest[key];
			});

		return sortedRequest;
	}

	savePaxData() {
		this.hotelService.clientInfos = cloneDeep(this.clientInfos);
		this.hotelService.guestsInfosArray = cloneDeep(this.guestsInfosArray);
	}

	confirmHotelBooking() {
		this.hotelService.setHotelConfirmationRequest(this.getHotelBookingConfirmationRequest());
		this.savePaxData();

		const elm: HTMLElement = document.getElementById("pax_billing_info_tab") as HTMLElement;
		ShareVars.enableBillingInfoTab = true;
		elm.classList.remove("disbaled-tab");
		elm.click();
	}

	getBillingInfos() {
		this.hotelService.setHotelConfirmationRequest(this.getHotelBookingConfirmationRequest());
		this.savePaxData();
		ShareVars.enableBillingInfoTab = true;
		this.scrollToTop();
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}

	testTrim(a): boolean {
		//check if there is unuseful white space before or after the string

		return !(a == null || a.trim().length >= 2);
	}

	scrollToTop() {
		let elm: HTMLElement = document.getElementById("nav_pax_tabs") as HTMLElement;
		setTimeout(() => {
			elm.scrollIntoView({ behavior: "smooth" });
		}, 500);
	}

	goToHotelAvailability() {
		this.navigationService.goToHotelAvailabilityPage();
	}

	validityCheck(guest, field, $event) {
		guest["isValid" + field] = $event.target.validity.valid && !this.testTrim(guest[field]);
		guest["isDuplicate"] = this.duplicateCheck(guest);
	}

	duplicateCheck(guest) {
		let fullName =
			(guest["firstName"] ? guest["firstName"] : "") +
			(guest["lastName"] ? guest["lastName"] : "");
		let count = 0;
		let tmp_name = "";
		this.guestsInfosArray.forEach((el) => {
			el.guestInfos.forEach((g) => {
				tmp_name = (g.firstName ? g.firstName : "") + (g.lastName ? g.lastName : "");
				if (tmp_name === fullName) {
					count++;
				}
			});
		});
		return count > 1;
	}

	isDuplicateEntries() {
		for (let room of this.guestsInfosArray) {
			for (let guest of room.guestInfos) {
				if (guest["isDuplicate"]) {
					this.isDuplicateFormEntries = true;
					return;
				}
			}
		}
		this.isDuplicateFormEntries = false;
	}

	isEmptyField(name) {
		return name == "" || name == null;
	}

	showInputError(guest_fname_field, guestFname) {
		if ((guest_fname_field.invalid && guest_fname_field.touched) || guestFname != null) {
			if (this.testTrim(guestFname) || guest_fname_field.invalid) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}
}
