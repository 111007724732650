import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { COUNTRIES } from "@app/flight-booking/components/flight-passenger/countryList";
import { BaseService } from "@app/shared/services/base.service";
import {
	VISA_ALL_SUBSCRIPTION_URL,
	VISA_FORMULES_URL,
	VISA_ONE_SUBSCRIPTION_URL,
	VISA_SEND_DATA_URL,
} from "./urls_b2b";
import { unescape as _unescape } from "lodash";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
const VISA_APPLICATION_PARAMS = "visaApplicationParams";

@Injectable({
	providedIn: "root",
})
export class VisaApplicationService {
	_formules: BehaviorSubject<any> = new BehaviorSubject(null);
	_visa: BehaviorSubject<any> = new BehaviorSubject(null);
	_passengers: BehaviorSubject<any> = new BehaviorSubject(null);
	_imageDoc: BehaviorSubject<any> = new BehaviorSubject(null);
	_selectedVisaRequest: BehaviorSubject<any> = new BehaviorSubject(null);

	constructor(private baseService: BaseService) {
		this._visa.next(this.getVisaApplicationParams());
	}

	get visa$(): Observable<any> {
		return this._visa.asObservable();
	}

	get formules$(): Observable<any> {
		return this._formules.asObservable();
	}

	get passengers$(): Observable<any> {
		return this._passengers.asObservable();
	}

	get selectedVisaRequest$(): Observable<any> {
		return this._selectedVisaRequest.asObservable();
	}

	getVisaApplicationParams() {
		return JSON.parse(this.baseService.getData(VISA_APPLICATION_PARAMS) || "{}");
	}

	getAllVisaRequests() {
		return this.baseService.tokenGetRequest(VISA_ALL_SUBSCRIPTION_URL);
	}

	getOneVisaRequest(id) {
		return this.baseService.tokenGetRequest(VISA_ONE_SUBSCRIPTION_URL + id);
	}

	setVisaApplicationParams(value) {
		this._visa.next(value);
		this.baseService.saveData(VISA_APPLICATION_PARAMS, value);
	}

	deleteVisaApplicationParams(key) {
		this.baseService.deleteData(key);
	}

	async getFormules() {
		this.baseService.tokenGetRequest(VISA_FORMULES_URL).subscribe((formules) => {
			this._formules.next(formules);
		});
	}

	submitVisaForm() {
		const visaValue = this._visa.value;
		let passengers = this._passengers.value;
		const images = this._imageDoc.value;

		passengers = passengers.map((passenger, index) => {
			passenger.documents = images[index];
			return passenger;
		});

		const request = {};
		request["information"] = {
			country_code: visaValue.formule.country_code,
			formule_id: visaValue.formule.id,
			departure_date: visaValue.travelDate,
			nbr_person: visaValue.totalPersons,
			nationality_code: visaValue.nationality_code,
			doc_type: visaValue.document,
		};

		request["passengers"] = passengers;

		return this.baseService.tokenPostRequest(VISA_SEND_DATA_URL, request);
	}

	countryNameFromCode(code) {
		const country = COUNTRIES.find((obj) => obj.alpha2Code === code);
		if (code == "WR") return "International";
		return country ? country.name : code;
	}

	// formuleDescription(id){
	//   if (id){
	//     let formule = this.formules[this.selectedVisaCountry].find(obj => { return obj.id === id})
	//     return `${formule.description} / ${formule.price} DZD`
	//   }
	// }
}
