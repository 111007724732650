export class SharedRoomModel {
	adult_nbr: number;
	child_nbr: number;
	child_age: number[];
	room_remark: string;

	constructor() {
		this.adult_nbr = 2;
		this.child_nbr = 0;
		this.child_age = [3, 3, 3, 3, 3, 3, 3, 3];
		this.room_remark = "";
	}
}
