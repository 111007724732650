import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { of, Observable } from "rxjs";
import {
	AIRPORT_AUTOCOMPLETE_API_URL,
	FLIGHT_AVAILABILITY_API_URL,
	UrlBuilder,
	URL_PARAMS,
	FLIGHT_FARE_CONDITIONS_API_URL,
	FLIGHT_CALENDAR_URL,
	FLIGHT_DELIVERY_CONFIRMATION_API_URL,
	FLIGHT_ETICKET_MINI_RULES_API_URL,
} from "./urls_b2c";

import {
	AIRPORT_AUTOCOMPLETE_B2B_URL,
	FLIGHT_AVAILABILITY_B2B_URL,
	FLIGHT_FARE_CONDITIONS_B2B_URL,
	FLIGHT_SCHEDULE_B2B_URL,
	FLIGHT_PROVIDERS_B2B_URL,
	FLIGHT_CALENDAR_B2B_URL,
	FLIGHT_ALL_PROVIDERS_B2B_URL,
	FLIGHT_ETICKET_MINI_RULES_B2B_URL,
} from "./urls_b2b";
import { BaseService } from "./base.service";
import { cloneDeep } from "lodash";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class FlightService {
	airlineDictionary = null;
	airportDictionnary = null;

	recommendation = null;
	arrival = new Array();
	departure = new Array();

	flightVars = {
		nrOfAdult: null,
		nrOfChild: null,
		nrOfInfant: null,
		nrOfInfantWithSeat: null,
		nrOfYouth: null,
		nrOfSenior: null,
		bounds: null,
		isDomesticFlight: null,
		cabinClass: null,
		tripType: null,
	};

	domesticFlight: boolean = null;
	confirmationRequest = null;

	searchData = null;
	availabilityResults: Map<string, any> = new Map<string, any>();

	clientInfos;

	adultsInfosArray;
	childrenInfosArray;
	infantsInfosArray;

	infantsSeatInfoArray;
	youthsInfoArray;
	seniorInfoArray;

	onholdbooking;
	corporate;
	canBeBookedOnhold: boolean = false;
	allowMinorSinglePassenger: boolean = false;
	paxNameCharacterLimit: number = 33;
	paxNameCharacterLimitINF: number = 22;
	extraServices = undefined;
	extraServicesPayload = {};

	zoneB: Map<string, boolean> = new Map<string, boolean>();
	withBaggage = false;
	refundable = false;
	directFlight = false;
	openReturn = false;
	calendar = false;

	extraFieldsArray;
	travellerProfiles;
	bookingFlightProvider = "";
	providersDictionary = [];
	requiredCustomerAddress = false;
	request = null;

	constructor(private baseService: BaseService, private httpClient: HttpClient) {}

	removeFlightPaxData() {
		this.setRecommendation(null);
		this.setDeparture(null);
		//this.setAirportDictionnary(null);
		this.flightVars.nrOfAdult = null;
		this.flightVars.nrOfChild = null;
		this.flightVars.nrOfInfant = null;
		//this.setTripType(null);
		this.setArrival(null);
		this.setDeparture(null);

		if (this.confirmationRequest && !this.confirmationRequest["onholdbooking"]) {
			this.resetAvailabilityResults();
		}
	}

	getProviders(): Observable<any> {
		return this.baseService.tokenGetRequest(FLIGHT_PROVIDERS_B2B_URL);
	}

	getAllFlightProviders(): Observable<any> {
		return this.baseService.tokenGetRequest(FLIGHT_ALL_PROVIDERS_B2B_URL);
	}

	removeAllData() {
		/*all except SearchData*/

		this.airlineDictionary = null;
		this.airportDictionnary = null;

		this.recommendation = null;
		this.arrival = new Array();
		this.departure = new Array();
		Object.keys(this.flightVars).forEach((v) => {
			this.flightVars[v] = null;
		});
		this.domesticFlight = null;

		this.confirmationRequest = null;

		this.zoneB.clear();
		this.availabilityResults.clear();
	}

	getFlightSearchParams(key) {
		return this.baseService.getData(key);
	}

	setFlightSearchParams(key, value) {
		this.baseService.saveData(key, value);
	}

	deleteFlightSearchParams(key) {
		this.baseService.deleteData(key);
	}

	getAirlineDictionary() {
		return this.airlineDictionary;
	}

	setAirlineDictionary(airlineDictionary) {
		this.airlineDictionary = cloneDeep(airlineDictionary);
	}

	getRecommendation() {
		return this.recommendation;
	}

	setRecommendation(recommendation) {
		//where is this used
		this.recommendation = cloneDeep(recommendation);
	}

	getArrival() {
		return this.arrival;
	}

	setArrival(arrival) {
		this.arrival = cloneDeep(arrival);
	}

	getDeparture() {
		return this.departure;
	}

	setDeparture(departure) {
		this.departure = cloneDeep(departure);
	}

	getAirportDictionnary() {
		return this.airportDictionnary;
	}

	setAirportDictionnary(airportDictionary) {
		this.airportDictionnary = cloneDeep(airportDictionary);
	}

	setFlightVarsBooking(flightVars) {
		Object.keys(flightVars).forEach((v) => {
			this.flightVars[v] = flightVars[v];
		});
	}

	getDomesticFlight() {
		return this.domesticFlight;
	}

	setDomesticFlight(domesticFlight) {
		this.domesticFlight = domesticFlight;
	}

	getConfirmationRequest() {
		return this.confirmationRequest;
	}

	setConfirmationRequest(request) {
		this.confirmationRequest = cloneDeep(request);
	}

	setExtrasServicePayload(payload) {
		this.extraServicesPayload = cloneDeep(payload);
	}

	setAvailabilityResults(results, provider: string) {
		this.availabilityResults.set(provider, cloneDeep(results));
	}

	resetAvailabilityResults() {
		this.availabilityResults.clear();
	}

	getAvailabilityResults(provider: string) {
		return this.availabilityResults.get(provider);
	}

	getSearchData() {
		return this.searchData;
	}

	getFlightNeedExchangeDocuments(provider) {
		const hasProvider = this.availabilityResults.has(provider);
		return hasProvider
			? this.availabilityResults.get(provider).flightNeedExchangeDocuments
			: null;
	}

	setSearchData(data) {
		this.openReturn = data["openReturn"];
		this.directFlight = data["directFlight"];
		this.withBaggage = data["withBaggage"];
		this.refundable = data["refundable"];
		this.searchData = cloneDeep(data);
	}

	public getExtraServices() {
		this.extraServices = [];
		for (let key in this.extraServicesPayload) {
			let value = this.extraServicesPayload[key];
			for (let i = 0; i < value.length; i++) {
				this.extraServices.push(value[i]);
			}
		}
		return this.extraServices;
	}

	public getExtraServicesTotal(extras) {
		let total = 0;
		for (let key in this.extraServicesPayload) {
			let value = this.extraServicesPayload[key];
			for (let i = 0; i < value.length; i++) {
				if (value[i].options.length > 0) {
					for (let j = 0; j < value[i].options.length; j++) {
						if (value[i].options[j].amount != undefined) {
							total = total + Number(value[i].options[j].amount);
						}
					}
				}
			}
		}
		if (total == 0) {
			if (this.confirmationRequest != null) {
				for (let i = 0; i < this.confirmationRequest.extraservices.lenght; i++) {
					let value = this.confirmationRequest.extraservices[i];
					if (value[i].options.length > 0) {
						for (let j = 0; j < value[i].options.length; j++) {
							if (value[i].options[j].amount != undefined) {
								total = total + Number(value[i].options[j].amount);
							}
						}
					}
				}
			}
		}
		return total;
	}

	public getAirports(request) {
		if (request === "") {
			return of([]);
		}

		let correctUrl;
		this.baseService.b2bUserType == "B2B"
			? (correctUrl = AIRPORT_AUTOCOMPLETE_B2B_URL)
			: (correctUrl = AIRPORT_AUTOCOMPLETE_API_URL);

		const urlBuilder = new UrlBuilder(correctUrl).addParam(URL_PARAMS.TERM, request);

		return this.baseService
			.tokenGetRequest(urlBuilder.getUrl())
			.map((response: Response) => response["predictions"]);
	}

	public getAvailability(request, handleError = true): Observable<any> {
		let urlBuilder;
		this.baseService.b2bUserType == "B2B"
			? (urlBuilder = new UrlBuilder(FLIGHT_AVAILABILITY_B2B_URL))
			: (urlBuilder = new UrlBuilder(FLIGHT_AVAILABILITY_API_URL));

		// TODO : find a way to add automatically all paremeters from the array into the url
		for (let i = 0; i < request["bounds"]; i++) {
			urlBuilder.addParam("departureDate_" + (i + 1), request["departureDate_" + (i + 1)]);
			urlBuilder.addParam("destination_" + (i + 1), request["destination_" + (i + 1)]);
			urlBuilder.addParam("origin_" + (i + 1), request["origin_" + (i + 1)]);
			if (request["cabinClass_" + (i + 1)]) {
				urlBuilder.addParam("cabinClass_" + (i + 1), request["cabinClass_" + (i + 1)]);
			}
		}

		if (request["tripType"] != "rt" && request["tripType"] != "mc") {
			request["openReturn"] = false;
		} else if (request["tripType"] == "mc") {
			for (let bound=0; bound<request["bounds"]; bound++) {
				urlBuilder.addParam(`nrOfDepartureCities_${bound+1}`, request[`nrOfDepartureCities_${bound+1}`])
				urlBuilder.addParam(`nrOfArrivalCities_${bound+1}`, request[`nrOfArrivalCities_${bound+1}`])
				for (let dep=0; dep<request[`nrOfDepartureCities_${bound+1}`]; dep++) {
					urlBuilder.addParam(`departureCity_${bound+1}_${dep+1}`, request[`departureCity_${bound+1}_${dep+1}`])
				}
				for (let dep=0; dep<request[`nrOfArrivalCities_${bound+1}`]; dep++) {
					urlBuilder.addParam(`arrivalCity_${bound+1}_${dep+1}`, request[`arrivalCity_${bound+1}_${dep+1}`])
				}
			}
		} else {
			urlBuilder
			.addParam("departureDate_2", request["departureDate_2"])
			.addParam("destination_2", request["origin_1"])
			.addParam("origin_2", request["destination_1"]);
		}

		urlBuilder
			.addParam("requestedMultiCity", request["requestedMultiCity"])
			.addParam("requestedFlightNonStop", request["requestedFlightNonStop"])
			.addParam("range", request["range"])
			.addParam("flexible", request["flexible"])
			.addParam("nrOfAdult", request["nrOfAdult"])
			.addParam("nrOfChild", request["nrOfChild"])
			.addParam("nrOfInfant", request["nrOfInfant"])
			.addParam("nrOfInfantWithSeat", request["nrOfInfantWithSeat"])
			.addParam("nrOfYouth", request["nrOfYouth"])
			.addParam("nrOfSenior", request["nrOfSenior"])
			.addParam("tripType", request["tripType"])
			.addParam("cabinClass", request["cabinClass"])
			.addParam("withBaggage", request["withBaggage"])
			.addParam("refundable", request["refundable"])
			.addParam("directFlight", request["directFlight"])
			.addParam("openReturn", request["openReturn"])
			.addParam("bounds", request["bounds"])
			.addParam("provider_id", request["provider_id"])
			.addParam("provider_code", request["provider_code"]);

		return this.baseService.tokenGetRequest(urlBuilder.getUrl(), handleError);
	}

	public getFlightCalendar(request, handleError): Observable<any> {
		let urlBuilder;
		this.baseService.b2bUserType == "B2B"
			? (urlBuilder = new UrlBuilder(FLIGHT_CALENDAR_B2B_URL))
			: (urlBuilder = new UrlBuilder(FLIGHT_CALENDAR_URL));
		urlBuilder.addParam("range", request["range"]);

		// TODO : find a way to add automatically all paremeters from the array into the url
		for (let i = 0; i < request["bounds"]; i++) {
			urlBuilder.addParam("departureDate_" + (i + 1), request["departureDate_" + (i + 1)]);
			urlBuilder.addParam("destination_" + (i + 1), request["destination_" + (i + 1)]);
			urlBuilder.addParam("origin_" + (i + 1), request["origin_" + (i + 1)]);
			if (request["cabinClass_" + (i + 1)]) {
				urlBuilder.addParam("cabinClass_" + (i + 1), request["cabinClass_" + (i + 1)]);
			}
		}

		if (request["tripType"] != "rt") {
			request["openReturn"] = false;
		}

		urlBuilder
			.addParam("requestedMultiCity", request["requestedMultiCity"])
			.addParam("requestedFlightNonStop", request["requestedFlightNonStop"])
			.addParam("flexible", request["flexible"])

			.addParam("nrOfAdult", request["nrOfAdult"])
			.addParam("nrOfChild", request["nrOfChild"])
			.addParam("nrOfInfant", request["nrOfInfant"])
			.addParam("nrOfInfantWithSeat", request["nrOfInfantWithSeat"])
			.addParam("nrOfYouth", request["nrOfYouth"])
			.addParam("nrOfSenior", request["nrOfSenior"])
			.addParam("tripType", request["tripType"])
			.addParam("cabinClass", request["cabinClass"])
			.addParam("withBaggage", request["withBaggage"])
			.addParam("refundable", request["refundable"])
			.addParam("directFlight", request["directFlight"])
			.addParam("openReturn", request["openReturn"])
			.addParam("bounds", request["bounds"])
			.addParam("provider_id", request["provider_id"])
			.addParam("provider_code", request["provider_code"]);

		return this.baseService.tokenGetRequest(urlBuilder.getUrl(), handleError);
	}

	public getFlightConditions(request) {
		let correctUrl;

		this.baseService.b2bUserType == "B2B"
			? (correctUrl = FLIGHT_FARE_CONDITIONS_B2B_URL)
			: (correctUrl = FLIGHT_FARE_CONDITIONS_API_URL);
		return this.baseService.tokenPostRequest(correctUrl, request);
	}

	public getMiniRulesFromEticket(booking_id) {
		let correctUrl;

		this.baseService.b2bUserType == "B2B"
			? (correctUrl = FLIGHT_ETICKET_MINI_RULES_B2B_URL + '/' + booking_id)
			: (correctUrl = FLIGHT_ETICKET_MINI_RULES_API_URL + '/' + booking_id);
		return this.baseService.tokenGetRequest(correctUrl);
	}

	getFlightOffers() {
		return this.httpClient.get("../../../assets/testing data/flightsOffers.json");
	}

	isZoneB(provider: any): boolean {
		if (!this.zoneB.has(provider.id)) {
			return false;
		}

		return this.zoneB.get(provider.id);
	}

	setIsZoneB(provider: any, value: boolean) {
		this.zoneB.set(provider.id, value);
	}

	public setBooking() {}

	public getExtraFieldsArray() {
		return this.extraFieldsArray;
	}

	public setExtraFieldsArray(extraFieldsArray) {
		return (this.extraFieldsArray = extraFieldsArray);
	}
	public getTravellerProfilesArray() {
		return this.travellerProfiles;
	}

	public setTravellerProfilesArray(travellerProfiles) {
		return (this.travellerProfiles = travellerProfiles);
	}

	getFlightSchedule(origin, destination, validatingCarrier, directFlight) {
		const urlBuilder = new UrlBuilder(FLIGHT_SCHEDULE_B2B_URL)
			.addParam("origin", origin)
			.addParam("destination", destination);

		if (validatingCarrier) {
			urlBuilder.addParam("validatingCarrier", validatingCarrier);
		}
		if (directFlight) {
			urlBuilder.addParam("directFlight", directFlight);
		}

		return this.baseService.tokenGetRequest(urlBuilder.getUrl());
	}
	confirmFlightDeliveryBooking(data) {
		const urlBuilder = new UrlBuilder(FLIGHT_DELIVERY_CONFIRMATION_API_URL);
		return this.baseService.tokenPostRequest(urlBuilder.getUrl(), data);
	}
	getBookingFlightProvider() {
		return this.bookingFlightProvider;
	}

	setBookingFlightProvider(provider: string) {
		this.bookingFlightProvider = provider;
	}

	getFlightProviders() {
		return this.providersDictionary;
	}
	setFlightProviders(providersDictionary) {
		this.providersDictionary = providersDictionary;
	}
}
