export const environment = {
    production: true,
    CLIENTNAME: 'algerie-booking',
    assets: 'rtw',
    clientDisplayName: 'RTW Booking',
    staticApi: 'https://static-api.algebratec.com/',
    serverURL: 'https://test-api.algebratec.com',
    key: 'nKlQBpQT8Yj2AiTsAufJ',
    phone: [],
    proAccessUrl: '', 
    delivery: false,
    mode: 'B2B'
};
