import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BaseService } from "@app/shared/services/base.service";
import { LoginService } from "@app/shared/services/login.service";
import { NavigationService } from "@app/shared/services/navigation.service";
import { environment } from "../../../../environments/environment";
import {
	B2B_ROUTE,
	FORGET_PASSWORD_B2B,
	HOME_ROUTE,
	LOGIN_B2B,
	REGISTER_B2B,
	RESET_PASSWORD_B2B,
} from "../../services/routes.pages";
import { ShareVars } from "../../services/share.vars";
import { interval, Subscription } from "rxjs";
import { IMAGES_URL } from "@app/shared/services/urls_b2b";
import { HttpClient } from "@angular/common/http";
import { InvoiceService } from "@app/shared/services/invoice.service";

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
	unreadMessages = 0;
	unreadInvoices = 0;
	subscriptionCountMessage: Subscription;
	isB2B = true;

	/******/

	isLogged = false;
	isGuest = false;
	showSignup = false;
	currentAgency;
	currentUser = null;
	showMenu: boolean = false;
	currentLang: string;
	currentCurrency: string;
	currentPlatform = null;

	availableLanguages = ["AR", "FR", "EN"];
	availableCurrenciesM = ["DZD", "EUR", "USD"];
	availableCurrencies = ["DZD"];

	currencyArray: Array<string>;
	currencyArrayM: Array<string>;
	languageArray: Array<string>;
	loginArray = ["MY_ACCOUNT", "AGENT", "MARKUPS", "MY_AGENCY", "CHANGE_PASSWORD", "LOGOUT"];
	loginArrayB2C = ["DASHBOARD", "LOGOUT"];
	loginArrayB2B = ["MY_AGENCY", "CHANGE_PASSWORD", "LOGOUT"];
	flightArray = ["NEW_BOOKING", "MANAGE_BOOKINGS", "AMADEUS_MARKUP", "FLIGHT_SCHEDULE"];
	packageArray = ["NEW_BOOKING", "MANAGE_BOOKINGS"]; /* MANAGE_PACKAGES */
	hotelArray = ["NEW_BOOKING", "MANAGE_BOOKINGS"];
	visaArray = ["NEW_BOOKING", "MANAGE_BOOKINGS"];
	analyticsArray = [
		"FLIGHT_ANALYTICS",
		"HOTEL_ANALYTICS",
		"VISA_ANALYTICS",
		"INSURANCE_ANALYTICS",
	];
	insuranceArray = ["NEW_CONTRACT", "MANAGE_CONTRACTS"];
	charterArray = ["NEW_BOOKING", "MANAGE_BOOKINGS"];

	shareVarsRef = ShareVars;

	HOME_ROUTE_URL = HOME_ROUTE;
	showConnect: boolean = true;
	activeTab = 0;

	staticUrl = IMAGES_URL;

	isRefreshBalance = false

	constructor(
		private navigationService: NavigationService,
		private httpClient: HttpClient,
		private baseService: BaseService,
		private loginService: LoginService,
		private invoiceService: InvoiceService,
		private cdr: ChangeDetectorRef,
		public router: Router
	) {
		if (ShareVars.lastActiveTab == 0) {
			ShareVars.lastActiveTab = 1;
		}

		this.isB2B = environment.mode === "B2B";

		if (this.isB2BUser()) {
			this.baseService.b2bUser.subscribe((data) => {
				this.currentUser = data;

				if (data && data["role_id"] == "AGENT") {
					if (this.flightArray.indexOf("AMADEUS_MARKUP") != -1) {
						this.flightArray.splice(this.flightArray.indexOf("AMADEUS_MARKUP"), 1);
					}
					if (this.loginArrayB2B.indexOf("MY_AGENCY") != -1) {
						this.loginArrayB2B.splice(this.loginArrayB2B.indexOf("MY_AGENCY"), 1);
					}

					if (this.loginArray.indexOf("MY_AGENCY") != -1) {
						this.loginArray.splice(this.loginArray.indexOf("MY_AGENCY"), 1);
					}
				}

				if (data) {
					//NOTE: always add the url that redirect us to home page from guard

					ShareVars.authorizations =
						data["product_authorizations"] || data["authorizations"] || [];
					const currentURL: string = window.location.pathname;

					switch (ShareVars.authorizations[0]) {
						case "AUTHORIZED_TO_USE_FLIGHT_BOOKING":
							if (
								currentURL == B2B_ROUTE + LOGIN_B2B ||
								currentURL.includes(B2B_ROUTE + RESET_PASSWORD_B2B) ||
								currentURL == "/" ||
								currentURL == B2B_ROUTE + REGISTER_B2B
							) {
								this.flightSearch();
							}
							break;

						case "AUTHORIZED_TO_USE_HOTEL_BOOKING":
							if (
								currentURL == HOME_ROUTE ||
								currentURL == B2B_ROUTE + LOGIN_B2B ||
								currentURL.includes(B2B_ROUTE + RESET_PASSWORD_B2B) ||
								currentURL == "/" ||
								currentURL == B2B_ROUTE + REGISTER_B2B
							) {
								this.hotelSearch();
							}
							break;

						case "AUTHORIZED_TO_USE_PACKAGE_BOOKING":
							if (
								currentURL == HOME_ROUTE ||
								currentURL == B2B_ROUTE + LOGIN_B2B ||
								currentURL.includes(B2B_ROUTE + RESET_PASSWORD_B2B) ||
								currentURL == "/" ||
								currentURL == B2B_ROUTE + REGISTER_B2B
							) {
								this.packageSearch();
							}
							break;

						case "AUTHORIZED_TO_USE_INSURANCE":
							if (
								currentURL == HOME_ROUTE ||
								currentURL == B2B_ROUTE + LOGIN_B2B ||
								currentURL.includes(B2B_ROUTE + RESET_PASSWORD_B2B) ||
								currentURL == "/" ||
								currentURL == B2B_ROUTE + REGISTER_B2B
							) {
								this.flightSearch();
							}
							break;
						case "AUTHORIZED_TO_USE_CHARTER":
							if (
								currentURL == HOME_ROUTE ||
								currentURL == B2B_ROUTE + LOGIN_B2B ||
								currentURL.includes(B2B_ROUTE + RESET_PASSWORD_B2B) ||
								currentURL == "/" ||
								currentURL == B2B_ROUTE + REGISTER_B2B
							) {
								this.flightSearch();
							}
							break;
						case "AUTHORIZED_TO_USE_VISA_BOOKING":
							if (
								currentURL == HOME_ROUTE ||
								currentURL == B2B_ROUTE + LOGIN_B2B ||
								currentURL.includes(B2B_ROUTE + RESET_PASSWORD_B2B) ||
								currentURL == "/" ||
								currentURL == B2B_ROUTE + REGISTER_B2B
							) {
								this.visaSearch();
							}
							break;
					}
				}
			});
		} else {
			this.baseService.getB2CState().subscribe((data) => (this.showConnect = data));
		}

		if (this.baseService.isB2BUserCookies()) {
			const currentURL: string = window.location.pathname;
			if (
				!(
					currentURL.includes(B2B_ROUTE + FORGET_PASSWORD_B2B) ||
					currentURL.includes(B2B_ROUTE + RESET_PASSWORD_B2B)
				)
			) {
				this.loginService.verifyToken().subscribe(
					(data) => {
						this.currentAgency = data;
						if (this.currentAgency["corporate"]) {
							this.flightArray.pop();
						}
						ShareVars.currentAgency = data;
						ShareVars.b2bLocalDepositBalance =
							this.currentAgency["local_deposit_balance"];
						ShareVars.b2bForeignDepositBalance =
							this.currentAgency["foreign_deposit_balance"];
						ShareVars.b2bOverdraw = this.currentAgency["local_authorized_overdraw"];
						this.getUnreadMessages();
						this.getUnreadInvoices();
						this.isLogged = true;
						const source = interval(3600000);
						this.subscriptionCountMessage = source.subscribe((val) => {
							this.getUnreadInvoices();
							this.getUnreadMessages();
						});
					},
					(error) => {
						console.log(error);
					}
				);
			}
		} else {
			if (!(environment.mode === "B2B")) {
				this.loginService.verifyToken().subscribe(
					(data) => {
						ShareVars.authorizations =
							data["product_authorizations"] || data["authorizations"] || [];
						this.currentUser = data;
						this.shareVarsRef.currentUser = data;
						if (this.currentUser.first_name !== "Guest") {
							this.isLogged = true;
						}
					},
					(error) => {
						console.log(error);
					}
				);
			} else {
				this.shareVarsRef.disableNavButtons = true;
			}
		}
	}

	ngOnInit() {
		this.initMenus();
		this.loginService.getCurrentPlatform().subscribe((data) => {
			this.currentPlatform = data;
			this.baseService.allow_epayment = data.allow_epayment;
			let footerInfos = {};
			let phones = [];
			for (let profile of data.profiles || []) {
				if (profile.social_type === "PHONE") {
					phones.push(profile.social_value);
					continue;
				}
				footerInfos[profile.social_type] = profile.social_value;
			}
			data.profiles = footerInfos;
			data.phones = phones;
			this.currentPlatform = data;
			this.baseService.currentPlatform = data;
		});
	}

	ngOnDestroy() {
		this.subscriptionCountMessage.unsubscribe();
	}

	getUnreadInvoices() {
		this.invoiceService.getTotalUnread().subscribe((data) => {
			this.unreadInvoices = data["unread_invoices"];
		});
	}

	getUnreadMessages() {
		//This function should get both unread messages and unread Invoices
		let token = this.baseService.getToken();
		let headers = this.baseService.createRequestHeaders(token);
		let url = environment.serverURL + "/api/b2b/communication/unread";
		this.httpClient.get(url, { headers, observe: "response" }).subscribe((data) => {
			this.baseService.unreadMessages = data["body"]["unread_communications"];
		});
		// this.baseService.getRequestCode(url,headers).subscribe(
		//   (data) => {
		//     this.unreadMessages = data.unread_communications;
		//   }
		// );
	}

	showSignUp(logModal, signUpModal, forgetPasswordModal) {
		ShareVars.signUpModalOn = true;
		ShareVars.initSignUpData();
		logModal.showModal = false;
		signUpModal.showModal = true;
		forgetPasswordModal.showModal = false;
	}

	showForgetPassword(logModal, signUpModal, forgetPasswordModal) {
		logModal.showModal = false;
		signUpModal.showModal = false;
		forgetPasswordModal.showModal = true;
	}

	logoutB2B() {
		this.loginService.logoutB2B().subscribe((data) => {
			if (data["status"] == 200) {
				this.baseService.deleteToken();
				this.baseService.deleteB2BUser();
				this.baseService.deleteUserTypeCookies();
				window.location.reload();
			}
		});
	}

	logout() {
		this.loginService.logout();
		this.navigationService.goToHomePage();
	}

	showLogin(logModal) {
		if (!this.isLogged) {
			logModal.showModal = true;
		}
	}

	showUserDashboard() {
		this.navigationService.goToUserDashboard();
	}

	goToAccountHistory() {
		this.navigationService.goToB2BAccountHistory();
	}

	navigateTo(page) {
		this.navigationService.navigate(page);
	}

	isArabic() {
		return this.baseService.isArabic();
	}

	showFlightTab() {
		if (!this.shareVarsRef.disableNavButtons) {
			window.location.pathname != HOME_ROUTE
				? (ShareVars.isBusy = true)
				: (ShareVars.isBusy = false);
			ShareVars.usingMenu = true;
			ShareVars.showFlightTab = true;
			ShareVars.showInsuranceTab = false;
			ShareVars.showPackageTab = false;
			ShareVars.showHotelTab = false;
			ShareVars.showVisaTab = false;
			ShareVars.showCharterTab = false;
			this.shareVarsRef.lastActiveTab = 1;
			this.navigationService.goToHomePage();
		}
	}

	showInsuranceTab() {
		this.shareVarsRef.lastActiveTab = 5;
		this.navigationService.goToInsurancePage();
	}

	showCharterTab() {
		this.shareVarsRef.lastActiveTab = 7;
		this.navigationService.goToCharterPage();
	}

	showPackageTab() {
		this.shareVarsRef.lastActiveTab = 2;
		this.navigationService.goToPackagePage();
	}

	showHotelTab() {
		if (!this.shareVarsRef.disableNavButtons) {
			window.location.pathname != HOME_ROUTE
				? (ShareVars.isBusy = true)
				: (ShareVars.isBusy = false);
			ShareVars.usingMenu = true;
			ShareVars.showHotelTab = true;
			ShareVars.showInsuranceTab = false;
			ShareVars.showFlightTab = false;
			ShareVars.showPackageTab = false;
			ShareVars.showVisaTab = false;
			ShareVars.showCharterTab = false;
			ShareVars.lastActiveTab = this.baseService.isB2CEnabled.getValue() ? 2 : 3;
			this.navigationService.goToHomePage();
		}
	}

	showVisaTab() {
		this.shareVarsRef.lastActiveTab = 8;
		this.navigationService.goToVisaPage();
	}

	showAnalyticsTab() {
		this.shareVarsRef.lastActiveTab = 9;
		this.navigationService.goToB2BFlightAnalytics();
	}

	getCurrentLang() {
		return this.baseService.getLanguage();
	}

	initMenus() {
		this.currencyArray = new Array(this.availableCurrencies.length);
		this.currencyArrayM = new Array(this.availableCurrenciesM.length);
		this.languageArray = new Array(this.availableLanguages.length);

		this.currentLang = this.getCurrentLang().toUpperCase();
		this.currentCurrency = "DZD";

		for (let i = 0; i < this.availableLanguages.length; i++) {
			this.languageArray[i] = this.availableLanguages[i];
		}

		for (let i = 0; i < this.availableCurrencies.length; i++) {
			this.currencyArray[i] = this.availableCurrencies[i];
		}

		for (let i = 0; i < this.availableCurrenciesM.length; i++) {
			this.currencyArrayM[i] = this.availableCurrenciesM[i];
		}
	}

	changeLanguage(language) {
		this.baseService.setLanguage(language);
		this.currentLang = this.getCurrentLang();
	}

	setCurrency(currency: string) {
		this.currentCurrency = currency;
	}

	changePassword() {
		this.navigationService.goToB2BChangePassword();
	}

	flightSearch() {
		this.showFlightTab();
	}

	flightSchedule() {
		this.navigationService.goToB2BFlightSchedule();
	}

	insuranceSearch() {
		this.showInsuranceTab();
	}

	charterSearch() {
		this.showCharterTab();
	}

	manageFlightBookings() {
		ShareVars.usingMenu = true;
		ShareVars.showFlightTab = true;
		ShareVars.showPackageTab = false;
		ShareVars.showHotelTab = false;
		ShareVars.showVisaTab = false;
		// this.shareVarsRef.lastActiveTab = 1;
		this.navigationService.goToB2BManageFlightBookings();
	}

	visaSearch() {
		this.showVisaTab();
	}

	manageVisaBookings() {
		ShareVars.usingMenu = true;
		// this.shareVarsRef.lastActiveTab = 1;
		this.navigationService.goToB2BManageVisaBookings();
	}

	hotelSearch() {
		this.showHotelTab();
	}

	manageHotelBookings() {
		ShareVars.usingMenu = true;
		ShareVars.showFlightTab = false;
		ShareVars.showPackageTab = false;
		ShareVars.showHotelTab = true;
		ShareVars.showVisaTab = false;
		// this.shareVarsRef.lastActiveTab = 3;
		this.navigationService.goToB2BManageHotelBookings();
	}

	goToFlightAnalytics() {
		ShareVars.usingMenu = true;
		ShareVars.showFlightTab = false;
		ShareVars.showPackageTab = false;
		ShareVars.showHotelTab = true;
		ShareVars.showVisaTab = false;
		// this.shareVarsRef.lastActiveTab = 3;
		this.navigationService.goToB2BFlightAnalytics();
	}
	goToHotelAnalytics() {
		ShareVars.usingMenu = true;
		ShareVars.showFlightTab = false;
		ShareVars.showPackageTab = false;
		ShareVars.showHotelTab = true;
		ShareVars.showVisaTab = false;
		// this.shareVarsRef.lastActiveTab = 3;
		this.navigationService.goToB2BHotelAnalytics();
	}
	goToVisaAnalytics() {
		ShareVars.usingMenu = true;
		ShareVars.showFlightTab = false;
		ShareVars.showPackageTab = false;
		ShareVars.showHotelTab = true;
		ShareVars.showVisaTab = false;
		// this.shareVarsRef.lastActiveTab = 3;
		this.navigationService.goToB2BVisaAnalytics();
	}
	goToInsuranceAnalytics() {
		ShareVars.usingMenu = true;
		ShareVars.showFlightTab = false;
		ShareVars.showPackageTab = false;
		ShareVars.showHotelTab = true;
		ShareVars.showVisaTab = false;
		// this.shareVarsRef.lastActiveTab = 3;
		this.navigationService.goToB2BInsuranceAnalytics();
	}

	packageSearch() {
		this.showPackageTab();
	}

	managePackageBookings() {
		this.navigationService.goToB2BManagePackageBookings();
	}

	goToManagePackages() {
		this.navigationService.goToB2BManagePackages();
	}

	goToManageAgent() {
		this.navigationService.goToB2BManageAgent();
	}

	goToFlightAmadeusMarkup() {
		this.navigationService.goToB2BFlightAmadeusMarkup();
	}

	goToMarkupsHistory() {
		this.navigationService.goToB2BMarkupHistory();
	}

	goToAgencyDashboard() {
		this.navigationService.goToB2BAgencyDashboard();
	}

	goToCommunicationsPage() {
		this.navigationService.goToCommunicationsPage();
	}
	goToInvoicesPage() {
		this.navigationService.goToInvoicesPage();
	}

	goToManageCharterPage() {
		this.navigationService.goToManageCharterPage();
	}

	goToManageInsurancePage() {
		this.navigationService.goToManageInsurancePage();
	}

	formatPrice(price) {
		return this.baseService.formatPrice(price);
	}

	getLastSelectedTab() {
		return ShareVars.lastActiveTab;
	}

	checkDisabledNavButtons() {
		return !this.shareVarsRef.disableNavButtons;
	}

	setLastActiveTab() {
		if (ShareVars.authorizations[0] !== "AUTHORIZED_TO_USE_FLIGHT_BOOKING") {
			ShareVars.lastActiveTab = 0;
		}
		if (ShareVars.lastActiveTab == 0) {
			this.baseService.b2bUser.subscribe((data) => {
				if (data) {
					ShareVars.authorizations =
						data["product_authorizations"] || data["authorizations"] || [];

					switch (ShareVars.authorizations[0]) {
						case "AUTHORIZED_TO_USE_FLIGHT_BOOKING":
							this.flightSearch();
							break;

						case "AUTHORIZED_TO_USE_HOTEL_BOOKING":
							this.hotelSearch();
							break;

						case "AUTHORIZED_TO_USE_PACKAGE_BOOKING":
							this.packageSearch();
							break;

						case "AUTHORIZED_TO_USE_INSURANCE":
							this.insuranceSearch();
							break;

						case "AUTHORIZED_TO_USE_VISA_BOOKING":
							this.visaSearch();
							break;
					}
				}
			});
		}
	}

	isB2BUser(): boolean {
		return this.baseService.isB2BUserCookies();
	}

	showProAccess(): boolean {
		return environment.proAccessUrl != "";
	}

	goToB2BRegister() {
		this.shareVarsRef.lastActiveTab = 0;
		this.navigationService.goToB2BRegister();
	}

	goToB2BLogin() {
		if (environment.proAccessUrl != "") {
			window.open(environment.proAccessUrl, "_blank").focus();
			return;
		}

		if (window.location.pathname != "/b2b/login") {
			ShareVars.isBusy = true;
		}

		this.navigationService.goToB2BLogin();
	}
	getRoutePath() {
		return this.router.url;
	}

	refreshBalance() {
		if (this.isRefreshBalance) return
		this.isRefreshBalance = true
		this.loginService.verifyToken().subscribe(
			(data) => {
				this.currentAgency = data;
				if (this.currentAgency["corporate"]) {
					this.flightArray.pop();
				}
				ShareVars.currentAgency = data;
				ShareVars.b2bLocalDepositBalance = this.currentAgency["local_deposit_balance"];
				ShareVars.b2bForeignDepositBalance = this.currentAgency["foreign_deposit_balance"];
				ShareVars.b2bOverdraw = this.currentAgency["local_authorized_overdraw"];
				this.getUnreadMessages();
				this.getUnreadInvoices();
				this.isLogged = true;
				const source = interval(3600000);
				this.subscriptionCountMessage = source.subscribe(() => {
					this.getUnreadInvoices();
					this.getUnreadMessages();
				});
				setTimeout(() => {
					this.isRefreshBalance = false
				}, 5000)
			},
			(error) => {
				console.log(error);
			}
		);
	}
}
