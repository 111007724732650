import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { ShareVars } from "../../services/share.vars";

const FR_FLAG =
	"https://cdn3.iconfinder.com/data/icons/142-mini-country-flags-16x16px/32/flag-france2x.png";
const EUR_FLAG =
	"https://cdn3.iconfinder.com/data/icons/142-mini-country-flags-16x16px/32/flag-european-union2x.png";
const EN_FLAG =
	"https://cdn3.iconfinder.com/data/icons/142-mini-country-flags-16x16px/32/flag-usa2x.png";
const AR_FLAG =
	"https://cdn3.iconfinder.com/data/icons/142-mini-country-flags-16x16px/32/flag-algeria2x.png";

@Component({
	selector: "app-navbar-menu",
	templateUrl: "./navbar-menu.component.html",
	styleUrls: ["./navbar-menu.component.scss"],
})
export class NavbarMenuComponent implements OnInit {
	@Input() menuTypeIndex: number;
	@Input() menuType: string;
	@Input() title: string;
	@Input() menuItems: [];
	@Input() menuItemsId: string;
	@Input() isB2C = false;
	@Input() logo: string;

	@Output() logOut = new EventEmitter<any>();
	@Output() goToDashboard = new EventEmitter<any>();
	@Output() changePassword = new EventEmitter<any>();
	@Output() accountHistory = new EventEmitter<any>();
	@Output() newBooking = new EventEmitter<any>();
	@Output() goToFlightAnalytics = new EventEmitter<any>();
	@Output() goToHotelAnalytics = new EventEmitter<any>();
	@Output() goToVisaAnalytics = new EventEmitter<any>();
	@Output() goToInsuranceAnalytics = new EventEmitter<any>();
	@Output() newContract = new EventEmitter<any>();
	@Output() flightSchedule = new EventEmitter<any>();
	@Output() manageBookings = new EventEmitter<any>();
	@Output() manageAgent = new EventEmitter<any>();
	@Output() amadeusMarkup = new EventEmitter<any>();
	@Output() markupsHistory = new EventEmitter<any>();
	@Output() agencyDashboard = new EventEmitter<any>();
	@Output() managePackages = new EventEmitter<any>();

	@Output() manageContracts = new EventEmitter<any>();

	shareVarsRef = ShareVars;
	constructor(private baseService: BaseService) {}

	ngOnInit() {}

	actionClick(menuItem) {
		switch (this.menuType) {
			case "currency":
				menuItem += "";
				this.setCurrency(menuItem);
				break;

			case "language":
				menuItem += "";
				this.changeLanguage(menuItem.toLowerCase());
				break;

			case "login":
				menuItem += "";

				switch (menuItem) {
					case "DASHBOARD":
						setTimeout(() => {
							this.goToDashboard.emit();
						}, 100);
						break;

					case "LOGOUT":
						setTimeout(() => {
							this.logOut.emit();
						}, 100);
						break;

					case "CHANGE_PASSWORD":
						setTimeout(() => {
							this.changePassword.emit();
						}, 100);
						break;

					case "MY_ACCOUNT":
						setTimeout(() => {
							this.accountHistory.emit();
						}, 100);
						break;

					case "AGENT":
						setTimeout(() => {
							this.manageAgent.emit();
						}, 100);
						break;

					case "MARKUPS":
						setTimeout(() => {
							this.markupsHistory.emit();
						}, 100);
						break;

					case "MY_AGENCY":
						setTimeout(() => {
							this.agencyDashboard.emit();
						}, 100);
						break;
				}

				break;

			case "flight":
				switch (menuItem) {
					case "NEW_BOOKING":
						this.newBooking.emit();
						break;

					case "FLIGHT_SCHEDULE":
						this.flightSchedule.emit();
						break;

					case "MANAGE_BOOKINGS":
						this.manageBookings.emit();
						break;

					case "AMADEUS_MARKUP":
						this.amadeusMarkup.emit();
						break;
				}
				break;

			case "visa":
				switch (menuItem) {
					case "NEW_BOOKING":
						this.newBooking.emit();
						break;

					case "MANAGE_BOOKINGS":
						this.manageBookings.emit();
						break;
				}
				break;

			case 'analytics':
				switch (menuItem) {
					case 'HOTEL_ANALYTICS':
					this.goToHotelAnalytics.emit();
					break;
					case 'FLIGHT_ANALYTICS':
					this.goToFlightAnalytics.emit();
					break;
					
					case 'VISA_ANALYTICS':
					this.goToVisaAnalytics.emit();
					break;
					case 'INSURANCE_ANALYTICS':
					this.goToInsuranceAnalytics.emit();
					break;
				}
				break;
			

			case "hotel":
				switch (menuItem) {
					case "NEW_BOOKING":
						this.newBooking.emit();
						break;

					case "MANAGE_BOOKINGS":
						this.manageBookings.emit();
						break;
				}
				break;

			case "package":
				switch (menuItem) {
					case "NEW_BOOKING":
						this.newBooking.emit();
						break;

					case "MANAGE_BOOKINGS":
						this.manageBookings.emit();
						break;

					case "MANAGE_PACKAGES":
						this.managePackages.emit();
						break;
				}
				break;

			case "insurance":
				switch (menuItem) {
					case "MANAGE_CONTRACTS":
						this.manageContracts.emit();
						break;

					case "NEW_CONTRACT":
						this.newContract.emit();
						break;
				}
				break;

			case "charter":
				switch (menuItem) {
					case "NEW_BOOKING":
						this.newBooking.emit();
						break;

					case "MANAGE_BOOKINGS":
						this.manageBookings.emit();
						break;
				}
				break;
		}

		this.shareVarsRef.showMenuNav[this.menuTypeIndex] = false;
	}

	changeLanguage(language) {
		this.baseService.setLanguage(language);
		this.title = this.getCurrentLang();
	}

	getCurrentLang() {
		return this.baseService.getLanguage();
	}

	setCurrency(currency: string) {
		this.title = currency;
	}

	getLangIcon(language: string) {
		const lang = language.toLowerCase();
		switch (lang) {
			case "fr":
				return FR_FLAG;

			case "eur":
				return EUR_FLAG;

			case "ar":
			case "dzd":
				return AR_FLAG;

			case "en":
			case "usd":
				return EN_FLAG;
		}
	}

	showMenuHover(menuIndex: number, menuID: string) {
		// let elm: HTMLElement = document.getElementById(menuID) as HTMLElement;
		// elm.focus();

		const val = this.shareVarsRef.showMenuNav[menuIndex];
		this.shareVarsRef.showMenuNav.fill(false);
		this.shareVarsRef.showMenuNav[menuIndex] = !val;
	}

	hideMenuFocusOut(menuIndex: number, menuID: string) {
		const elm: HTMLElement = document.getElementById(menuID) as HTMLElement;

		setTimeout(() => {
			elm.blur();
			this.shareVarsRef.showMenuNav[menuIndex] = false;
		}, 100);
	}

	isArabic() {
		return this.baseService.isArabic();
	}
}
